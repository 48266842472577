import React from 'react'
import Helmet from 'react-helmet';
import { Link, graphql } from "gatsby";
import ContactBar from '../components/ContactBar';
import BookNow from '../components/BookNow';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Layout from '../components/Layout';

const BlogPage = ({ data, location }) => {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout location={location}>
      <div className="page">
        <Helmet>
          <title>Blog - TCM Praxis Paola Nakahara-Hoffmann</title>
          <meta property="og:title" content="Blog - TCM Praxis Paola Nakahara-Hoffmann" />
        </Helmet>
        <ContactBar className="large-screen" />
        <div className="container blog-posts">
          {posts
            .filter(post => post.node.frontmatter.title.length > 0)
            .map(({ node: post }) => {
              return (
                <div className="blog-post-preview" key={post.id}>
                  <h1>
                    <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
                  </h1>
                  <div className="meta">
                    <span className="author">{post.frontmatter.author}</span>
                    <span className="date">{post.frontmatter.date}</span>
                    <span className="category">{post.frontmatter.categories}</span>
                  </div>
                  <p>{post.excerpt}<Link className="read-more" to={post.frontmatter.path}>mehr lesen</Link></p>
                </div>
              );
            })}
        </div>
        <BookNow />
        <Contact />
        <Footer />
      </div>
    </Layout>
  );
}

export default BlogPage;

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "dddd, Do MMMM YYYY", locale: "de")
            path
            author
            categories
          }
        }
      }
    }
  }
`;
